import React, { useState } from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Parser from "html-react-parser"
import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress } from "@material-ui/core"

import Button from "components/button"

const QuoteForm = (props) => {
  const { formId, 36: phoneNumber, 2: leadEmail, 8: key, 9: retailerEmail } = props
  const { allGfForm } = useGravityData()
  const form = allGfForm.nodes.find((o) => +o.formId === +formId)
  const { apiURL } = form || {}

  const [tab, setTab] = useState(null)

  const initialValues = {
    input_2: key,
    input_3: leadEmail,
    input_4: retailerEmail
  }
  const [values, setValues] = useState(initialValues)

  const [submitForm, response] = useFetch(`${apiURL}/submissions`, {
    method: "POST",
    "Content-Type": "application/x-www-form-urlencoded"
  })

  const changeTab = (number) => {
    switch (number) {
      case 0:
        setValues({ ...initialValues, input_6: "messaged" })
        break

      case 1:
        setValues({ ...initialValues, input_6: "called" })
        break

      case 2:
        setValues({ ...initialValues, input_6: "rejected" })
        break

      default:
    }

    if (tab === number) {
      setTab(null)
    } else {
      setTab(number)
    }
  }

  const handleSubmit = () => {
    if (!values) return
    const formData = new FormData()

    Object.keys(values).map((key) => formData.append(key, values[key]))

    submitForm({ body: formData })
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  if (!response.data && !response.error) {
    return (
      <Container>
        <Tabs>
          <TabButton active={tab === 0} onClick={() => changeTab(0)}>
            Message
          </TabButton>
          <TabButton
            active={tab === 1}
            onClick={() => {
              changeTab(1)
            }}
          >
            Phone
          </TabButton>
          <TabButton active={tab === 2} onClick={() => changeTab(2)}>
            Reject
          </TabButton>
        </Tabs>

        <Content>
          {tab === 0 && (
            <TabPanel>
              <Typography gutterBottom>
                Please enter your message with the quote details below. There is also an option to upload a quote in
                PDF, JPG or PNG format. You may also respond back with any questions you may have, please be sure to
                include your contact information such as email or telephone number.
              </Typography>
              <Typography gutterBottom>
                Once the form has been completed, please click the Confirm button to submit your quote/message directly
                to the customer.
              </Typography>

              <TextField
                css={css`
                  margin-bottom: 30px;
                `}
                value={values.input_1}
                name="input_1"
                label="Message"
                variant="filled"
                fullWidth
                multiline
                rows={4}
                onChange={handleChange}
              />

              <input
                css={css`
                  margin-bottom: 30px;
                `}
                type="file"
                onChange={(e) => setValues({ ...values, input_5: e.target.files[0] })}
                id="file-upload-input"
              />

              <Button onClick={handleSubmit} disabled={response?.called}>
                {response.loading ? <CircularProgress size={20} /> : `Send Message`}
              </Button>
            </TabPanel>
          )}

          {tab === 1 && (
            <TabPanel>
              <Typography gutterBottom>Phone: {phoneNumber}</Typography>
              <Button onClick={handleSubmit} disabled={response?.called}>
                {response.loading ? <CircularProgress size={20} /> : `Confirm and close lead`}
              </Button>
            </TabPanel>
          )}

          {tab === 2 && (
            <TabPanel>
              <Typography gutterBottom>
                Please select a reason below as to why you are rejecting this lead and click Confirm. Once confirmed,
                this lead will not be available for you to quote anymore, and we may re-assign this lead to another
                WRCLA retailer.
              </Typography>

              <FormControl
                css={css`
                  min-width: 300px;
                  margin-bottom: 20px;
                `}
              >
                <InputLabel id="rejected-reason">Reason for rejection</InputLabel>
                <Select
                  labelId="rejected-reason"
                  id="rejected-reason"
                  name="input_12"
                  value={values.input_12}
                  onChange={handleChange}
                >
                  <MenuItem value="Order too small">Order too small</MenuItem>
                  <MenuItem value="We don't carry, stock or mill this product">
                    We don't carry, stock or mill this product
                  </MenuItem>
                  <MenuItem value="Lead is too far away">Lead is too far away</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>

              {values?.input_12 === "Other" && (
                <TextField
                  css={css`
                    margin-bottom: 30px;
                  `}
                  value={values.input_10}
                  name="input_10"
                  label="Other reason"
                  variant="filled"
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleChange}
                />
              )}

              <Button onClick={handleSubmit} disabled={response?.called}>
                {response.loading ? <CircularProgress size={20} /> : `Confirm and close lead`}
              </Button>
            </TabPanel>
          )}
        </Content>
      </Container>
    )
  } else if (response.data) {
    return (
      <Message success>
        <Typography>{response?.data?.confirmation_message && Parser(response.data.confirmation_message)}</Typography>
      </Message>
    )
  } else {
    return (
      <Message>
        <Typography component="div">Something went wrong. Please try again.</Typography>
      </Message>
    )
  }
}

const Message = styled.div`
  width: 100%;
  border: 1px solid ${(props) => (props.success ? "#2dad38" : "#e24141")};
  padding: 10px 14px;
  margin-top: 20px;
`
const Container = styled.div``

const Tabs = styled.div`
  margin: 20px 0;
  > button {
    margin-right: 20px;
  }
`

const TabButton = styled(Button)`
  ${({ active }) =>
    active
      ? css`
          border-bottom: 2px solid #00b040 !important;
        `
      : css`
          border-bottom: 2px solid transparent !important;
        `}
`

const Content = styled.div`
  margin: 30px 0;
`

const TabPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const useGravityData = () => {
  const { allGfForm, allWpRetailer } = useStaticQuery(
    graphql`
      query {
        allGfForm {
          nodes {
            formId
            title
            slug
            apiURL
            labelPlacement
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              isRequired
              checkboxLabel
              conditionalLogic
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              id
              name
              type
              message
            }
          }
        }
      }
    `
  )
  return { allGfForm, allWpRetailer }
}

const useFetch = (endpoint, options = {}) => {
  const [state, setState] = useState({
    error: null,
    data: null,
    loading: false,
    called: false
  })

  const callback = async (args) => {
    setState({
      ...state,
      called: true,
      loading: true,
      error: null
    })

    // const params = args && createQueryString(args)

    await fetch(endpoint, {
      method: "get",
      ...options,
      ...args
    })
      .then(async (response) => {
        const json = await response.json()

        setState({
          ...state,
          loading: false,
          data: response.status === 200 ? json : null,
          called: true,
          error: response.status !== 200 ? json : null
        })
      })
      .catch((error) => {
        setState({ ...state, error, loading: false, called: true })
      })
  }

  return [callback, state]
}

export default QuoteForm
