import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
// import moment from 'moment'

const LeadInfo = (props) => {
  const {
    id,
    date_created,
    // 2: email,
    6: company,
    34: firstName,
    35: lastName,
    22: retailerName,
    44.1: product_1,
    44.2: product_2,
    44.3: product_3,
    44.4: product_4,
    44.5: product_5,
    44.6: product_6,
    44.7: product_7,
    44.8: product_8,
    45: otherProductType,
    29: streetAddress,
    30: city,
    31: region,
    32: zipcode,
    33: country,
    20: projSquareFt,
    21: timeFrameNeeded,
    11: status,
    16: sidingProfile,
    17: grade,
    18: season,
    19: sidingWidth,
    10: additionalInfo,
    51: projectMaterials
  } = props || {}

  const requestedProducts = [product_1, product_2, product_3, product_4, product_5, product_6, product_7, product_8]
    .filter((e) => !!e)
    .join(", ")

  const fullAddress = [streetAddress, city, region, country, zipcode].filter((e) => !!e).join(", ")

  return (
    <Container>
      {retailerName && <Typography variant="h2">{retailerName}</Typography>}
      {id && (
        <Typography variant="h2" gutterBottom>
          Request #{id}
        </Typography>
      )}
      <Info>
        <div>
          {firstName && lastName && (
            <Typography>
              <b>Name:</b> {`${firstName} ${lastName}`}
            </Typography>
          )}

          {company && (
            <Typography>
              <b>Company:</b> {company}
            </Typography>
          )}

          {date_created && (
            <Typography>
              <b>Date:</b> {date_created}
            </Typography>
          )}
        </div>

        <div>
          {fullAddress && (
            <Typography>
              <b>Address:</b> {`${fullAddress}`}
            </Typography>
          )}
        </div>

        <div>
          {requestedProducts && (
            <Typography>
              <b>Product type{requestedProducts.includes(",") && "s"} requested:</b> {requestedProducts}
            </Typography>
          )}
          {otherProductType && (
            <Typography>
              <b>Other product type: </b>
              {otherProductType}
            </Typography>
          )}
          {grade && (
            <Typography>
              <b>Grade:</b> {grade}
            </Typography>
          )}

          {sidingProfile && (
            <Typography>
              <b>Siding Profile:</b> {sidingProfile}
            </Typography>
          )}

          {sidingWidth && (
            <Typography>
              <b>Siding Width:</b> {sidingWidth}
            </Typography>
          )}

          {season && (
            <Typography>
              <b>Season:</b> {season}
            </Typography>
          )}

          {additionalInfo && (
            <Typography>
              <b>Additional Info:</b> {additionalInfo}
            </Typography>
          )}
        </div>

        <div>
          {projSquareFt && (
            <Typography>
              <b>Project square footage:</b> {projSquareFt}
            </Typography>
          )}

          {timeFrameNeeded && (
            <Typography>
              <b>Time frame needed:</b> {timeFrameNeeded}
            </Typography>
          )}

          {status && (
            <Typography>
              <b>Request status:</b> {status}
            </Typography>
          )}
        </div>
      </Info>
      <div>
        {projectMaterials && (
          <div>
            <Typography>
              <b>Project Materials:</b>
            </Typography>
            <Typography style={{ whiteSpace: "pre-wrap", marginBottom: "24px" }}>{projectMaterials}</Typography>
          </div>
        )}
      </div>
    </Container>
  )
}

const Container = styled.div``
const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 100%;
    margin-bottom: 20px;
  }
  @media (min-width: 800px) {
    > div {
      width: calc(50% - 15px);
    }
  }
`

export default LeadInfo
