import React, { useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Typography, CircularProgress } from "@material-ui/core"

// import app components
import SidebarContent from "components/sidebarContent"
import getParameter from "utils/getParameter"
import { getUrlPath } from "utils/getUrlPath"
import LeadInfo from "components/leadForm/LeadInfo"
import QuoteForm from "components/leadForm/QuoteForm"
import useFetch from "utils/useFetch"

const QuoteRequestForm = (props) => {
  const {
    data: {
      wpPage: {
        title,
        uri,
        templateRetailQuoteLead: { formId }
      },
      wp: {
        themeSettings: {
          siteOptions: { defaultHeroImage }
        }
      }
    }
  } = props

  const key = getParameter("key")

  const [getLeadInfo, { called, data, error, loading }] = useFetch(`${process.env.GATSBY_WP}/wp-json/zoho/v1/leadinfo`)

  useEffect(() => {
    if (typeof window !== `undefined` && getParameter("key") && !called) {
      getLeadInfo({ key })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  return (
    <SidebarContent
      image={defaultHeroImage}
      title={title}
      slug={getUrlPath(uri)}
      hideSidebar={true}
      hideSidebarOnMobile
      hideHeader={false}
      edgesSize={"default"}
    >
      {!key && typeof window !== `undefined` && (
        <Message>
          <Typography>Lead not found.</Typography>
        </Message>
      )}

      {loading && <CircularProgress />}

      {error && (
        <Message>
          {error.message === "Lead not found" ? (
            <Typography component="div">{error.message}.</Typography>
          ) : (
            <Typography component="div">Something went wrong. Please try again.</Typography>
          )}
        </Message>
      )}

      {data && (
        <>
          <LeadInfo {...data} />
          {data[11] === "open" ? (
            <QuoteForm {...data} formId={formId} />
          ) : (
            <Message>
              <Typography>This lead has been closed.</Typography>
            </Message>
          )}
        </>
      )}
    </SidebarContent>
  )
}

const Message = styled.div`
  width: 100%;
  border: 1px solid ${(props) => (props.success ? "#2dad38" : "#e24141")};
  padding: 10px 14px;
  margin-top: 20px;
  margin-bottom: 60px;
`

export const CollectionQuery = graphql`
  query RetailQuoteLead($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      uri
      templateRetailQuoteLead {
        formId
      }
    }
    allGfForm {
      nodes {
        formId
        title
        slug
        apiURL
        labelPlacement
        descriptionPlacement
        formFields {
          id
          label
          labelPlacement
          isRequired
          checkboxLabel
          conditionalLogic
          description
          descriptionPlacement
          type
          choices
          content
          errorMessage
          inputMaskValue
          visibility
          cssClass
          placeholder
          size
          defaultValue
          maxLength
        }
        button {
          text
        }
        confirmations {
          id
          name
          type
          message
        }
      }
    }
    allWpRetailer(filter: { Retailer: { email: { ne: "" }, quoteButton: { eq: true } } }) {
      retailers: nodes {
        databaseId
        title
        Retailer {
          premiumRetailer {
            ... on WpMember {
              Members {
                excludeFromOrganicSearchResults
                smallMemberLogo {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
    wp {
      themeSettings {
        siteOptions {
          defaultHeroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default QuoteRequestForm
